@import "~@/styles/variables";
























































































.token-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
  padding-right: 0;
  padding-bottom: 0;

  > * {
    margin-right: $input-btn-padding-x-sm;
    margin-bottom: $input-btn-padding-y-sm;
  }
}
.token {
  display: flex;
  background: $pp-snowball;
  border-radius: 3px;
  color: $pp-primary-blue;
  padding-left: 0.5em;
  padding-bottom: 1px;
  align-items: center;

  &__close {
    font-size: 125%;
    padding: 0.3em 0.5em;
    color: inherit;
  }
}

::v-deep .token-input {
  border: none;
  box-shadow: none;
  height: auto;
  background: transparent;
  min-width: 50px;
  padding: 0.5em 0;
}

.token-list-move,
.token-list-enter-active,
.token-list-leave-active {
  transition: all 0.1s ease-out;
}

.token-list-enter,
.token-list-leave-to {
  transform: translateX(-15px);
  opacity: 0;
}
