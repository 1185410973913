@import "~@/styles/variables";





































































































































































































.callout {
  background-color: $pp-snowball;
}
